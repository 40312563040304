<template>
	<div class="content">
		<Header :title="$t('lang.JP_title')"></Header>
		<div class="make">
			<div class="text">
				<p>{{ $t('lang.JP_p1') }}</p>
				<p>{{ $t('lang.JP_p2') }}</p>
				<p>{{ $t('lang.JP_p3') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from "@/components/Header";
	import HB from "@/assets/js/bridge";
	export default {
		components: {
			Header
		},
		data() {
			return {
				language: '',
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			localStorage.setItem('lang', this.language);
			this.$i18n.locale = localStorage.getItem("lang");
		},
		mounted() {
			
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	// * {
	// 	font-family: PingFang;
	// }
	.content {
		width: 100%;
		height: 3rem;
		background-color: #FFFFFF;
		padding-top: 0.2rem;
	}
	.make {
		width: 92%;
		margin: 0 auto;
		border-radius: 27px;
	}
	.text {
		width: 100%;
		margin: 0 auto;
		padding-top: 0.3rem;
		padding-bottom: 0.25rem;
		p {
			font-size: 0.28rem;
			line-height: 0.4rem;
			font-weight: 400;
			color: #999999;
			margin-bottom: 0.08rem;
		}
	}
</style>